export default grapesjs.plugins.add('cc-promo-banner', (editor, options) => {
	editor.BlockManager.add(
		'PromoBanner', {
			category: 'Commerce',
			label: 'Promo Bammer',
			attributes: {class: 'fa fa-gift'},
			content: `<div class="cc-promo-banner" data-gjs-type="cc-promo-banner" data-initial-state="true">Configure the Settings for the Promo Banner</div>`
		}
	);

	// Get DomComponents module
	var comps = editor.DomComponents;
	
	// Get the model and the view from the default Component type
	var defaultType = comps.getType('default');
	var defaultModel = defaultType.model;
	var defaultView = defaultType.view;

	// The `input` will be the Component type ID
	comps.addType('cc-promo-banner', {
		// Define the Model
		model: defaultModel.extend({
			// Extend default properties
			defaults: Object.assign(
				{},
				defaultModel.prototype.defaults, {
					// Can't drop other elements inside it
					droppable: false,
					// Traits (Settings)
					traits: [
						{
							type: 'text', // Type of the trait
							label: 'Promo Image URL', // The label you will see in Settings
							name: 'imageURL', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Promo Link Title', // The label you will see in Settings
							name: 'imageTitle', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Promo Link URL', // The label you will see in Settings
							name: 'linkURL', // The name of the attribute/property to use on component
						},
							{
							type: 'checkbox',
							label: 'Open in new Tab',
							name: 'openInNewTab'
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible From', // The label you will see in Settings
							name: 'cccStartDate', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible To', // The label you will see in Settings
							name: 'cccEndDate', // The name of the attribute/property to use on component
						}
					],
					attributes: {
						imageURL: '',
						imageTitle: '',
						linkURL: '',
						openInNewTab: false,
						cccStartDate: '',
						cccEndDate: ''
					}
				}
			),
			init() {
				this.on('change:attributes:imageURL', this.handleTraitChange);
				this.on('change:attributes:imageTitle', this.handleTraitChange);
				this.on('change:attributes:linkURL', this.handleTraitChange);
				this.on('change:attributes:openInNewTab', this.handleTraitChange);
				this.on('change:attributes:cccStartDate', this.handleTraitChange);
				this.on('change:attributes:cccEndDate', this.handleTraitChange);
			},
			handleTraitChange() {
				let imgExtArray = ["jpg", "jpeg", "png", "gif", "svg"];

				if (this.isExtension(this.getAttributes().imageURL, imgExtArray)) {
					this.attributes.content = this.generateHTML(false);
					this.view.el.innerHTML = this.generateHTML(false);
					$(this.view.el).removeAttr('data-initial-state');
					delete this.view.attr['data-initial-state'];
					delete this.attributes.attributes['data-initial-state'];
				} else {
					alert('The given URL does not reference an image. Please update the input.');
				}
			},
			toHTML: function() {
				return this.generateHTML(true);
			},
			generateHTML(includeParentDOMElement) {
				if (typeof includeParentDOMElement == 'undefined') {
					includeParentDOMElement = false;
				}

				let elementString = '';
				let timedAttributes = '';

				if (includeParentDOMElement) {
					if (this.getAttributes().cccStartDate && this.getAttributes().cccEndDate) {
						timedAttributes = ' cccStartDate="' + this.getAttributes().cccStartDate + '" cccEndDate="' +  this.getAttributes().cccEndDate + '"';
					}
					elementString = elementString + '<div class="cc-promo-banner overflow-hidden" data-gjs-type="cc-promo-banner"' + timedAttributes + '>';
				}

				let target = '';
				if (this.getAttributes().openInNewTab) {
					target = ' target="_blank"';
				}

				elementString = elementString + '<div><a href="'+ this.getAttributes().linkURL +  '"' + target + '>' +
					'<img style="max-width: 100%;" src="' + this.getAttributes().imageURL +  '" alt="' + this.getAttributes().imageTitle + '" title="' + this.getAttributes().imageTitle  +'"/>' +
					'</a></div>'
			
				if (includeParentDOMElement) {
					elementString = elementString + '</div>';
				}

				return elementString;
			},
			isExtension(testFileName, extArray) {
				// Get the extension from the filename
				var ext = String(testFileName).split('.').pop();
				var result = false;
				var i;

				if (ext) {
					ext = ext.toLowerCase();
					for (i = 0; i < extArray.length; i++) {
						if (extArray[i].toLowerCase() === ext) {
							result = true;
							break;
						}
					}
				}
				return result;
			}
		},
		// The second argument of .extend are static methods and we'll put inside our
		// isComponent() method. As you're putting a new Component type on top of the stack,
		// not declaring isComponent() might probably break stuff, especially if you extend
		// the default one.
		{
			isComponent(el) {     
				if (el.getAttribute && el.getAttribute('data-gjs-type') == 'cc-promo-banner') {
					return {
						type: 'cc-promo-banner'
					};
				}
			}
		}
	)
	});
});